/* eslint-disable guard-for-in */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-underscore-dangle */

/**
 * #######################################################@
 *
 * Skills chart settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Step,
	StepLabel,
	Stepper
} from "@mui/material";
import { useRef, useState, useEffect } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import { t } from "i18next";
import lod_ from "lodash";

import { useDispatch } from "react-redux";

import { display } from "redux-react/reducers/snackBarReducer";
import FormAction from "redux-react/actions/formAction";

import Step1ChannelChoice from "./steps/1. ChannelChoice";
import Step2CommonPart from "./steps/2. CommonPart";
import Step3ConfigPart from "./steps/3. ConfigPart";
import Step4Mapping from "./steps/4. Mapping";
import Step5AdvancedSettings from "./steps/5. AdvancedSettings";
import GoogleScrapingConfiguration from "./steps/3. ConfigPart/specific/googleScraping";

export default function EditChannelDialog({
	open,
	handleClose,
	handleSave,
	channel: channelCode = null,
	dictionaryChannelName
}) {
	const dispatch = useDispatch();
	const topContainer = useRef(null);
	const [loading, setLoading] = useState(true);
	const [editingChannel, setEditingChannel] = useState({});
	/**
	 * Skeleton
	 */
	const [commonPart, setCommonPart] = useState({});
	const [configPart, setConfigPart] = useState({});
	const [inputsPart, setInputsPart] = useState({});

	const defaultValueInputs = {
		answerTone: {
			value: "Formel",
			active: false
		},
		emojisCount: {
			value: 0,
			active: false
		},
		youForm: {
			value: "vouvoiement",
			active: false
		},
		maximumWords: {
			value: 50,
			active: false
		},
		temperature: {
			value: 0.5,
			active: false
		}
	};

	const [errorUniqueFields, setErrorUniqueFields] = useState([]);

	const [selectedChannel, setSelectedChannel] = useState({});
	const [selectedChannelSubType, setSelectedChannelSubType] = useState({});

	// Mapping part
	const [mappingObject, setMappingObject] = useState({});
	const [mapping, setMapping] = useState([]);

	// Used for store extra datas across the steps
	const [extraDatas, setExtraDatas] = useState({});

	/**
	 * Active step
	 */
	const [activeStep, setActiveStep] = useState(0);

	const [extraSteps, setExtraSteps] = useState([]);

	/**
	 *
	 * Steps labels
	 */
	const steps = [
		i18n.t("CHANNEL.STEPS.CHOICE.canalChoice"),
		i18n.t("CHANNEL.STEPS.CHOICE.canalConfiguration"),
		i18n.t("CHANNEL.STEPS.CHOICE.canalInfos"),
		i18n.t("CHANNEL.STEPS.CHOICE.canalMapping"),
		...extraSteps
		// "Paramètres avancés"
		// i18n.t("CHANNEL.STEPS.CHOICE.signature"),
		// i18n.t("CHANNEL.STEPS.CHOICE.answerConfig")
	];

	const shortSteps = [
		i18n.t("CHANNEL.STEPS.CHOICE.canalChoice"),
		i18n.t("CHANNEL.STEPS.CHOICE.canalConfiguration"),
		i18n.t("CHANNEL.STEPS.CHOICE.canalInfos")
	];

	const [displayedSteps, setDisplayedSteps] = useState(steps);

	/**
	 * Can user go to next step
	 */
	const [stepValid, setStepValid] = useState(false);
	/**
	 * Is last step
	 */
	const isLastStep = activeStep === displayedSteps.length - 1;
	/**
	 * Close modale
	 */
	function close(e, reason) {
		handleClose();
	}

	function getStringifiedObject(object, mapping) {
		let stringifiedObject = JSON.stringify(object);

		for (let row of mapping) {
			let value = row.value;

			switch (row.type) {
				// Map with a single value from dictionary output
				case "singleValue":
					{
						// Need to replace the string by a handlebar-like expression
						let valueToReplace = `"${value}"`;
						let newValue = ` {{{ json ${value} }}} `;
						stringifiedObject = stringifiedObject.replace(valueToReplace, newValue);
					}
					break;
				// Map to a computed string like name : "{{{ first }}} - {{{ last }}}"
				case "customValue":
					// Do nothing
					break;
				default:
					break;
			}
		}

		return stringifiedObject;
	}

	/**
	 * Submit profile
	 */
	function submit() {
		let stringfiedContact = getStringifiedObject(mappingObject.contact ?? {}, mapping);
		let stringfiedContext = getStringifiedObject(mappingObject.context ?? {}, mapping);

		let newChannel = {
			...commonPart,
			// Add channel type
			type: selectedChannel.type,
			subType: selectedChannel.subType,
			config: configPart,
			answerGenerateConfig: inputsPart,
			contact: stringfiedContact,
			context: stringfiedContext
		};

		const onSuccess = res => {
			handleSave(res?.item);
		};
		let data = { values: newChannel, target: "channel", unique: [] };

		if (channelCode) {
			data.actions = ["updateChannelAction"];
			dispatch(FormAction.updateItem(editingChannel._id, data, onSuccess));
		} else {
			data.actions = ["createChannelAction"];
			dispatch(FormAction.addItemEmpty(data, onSuccess));
		}
	}

	/**
	 * User can go to next step
	 */
	const validStep = (val = true) => {
		setStepValid(val);
	};
	/**
	 * Go to next step
	 */
	const handleNext = () => {
		// Valid common part, do not valid when we're editing a channel
		if (activeStep === 1 && !channelCode) {
			dispatch(
				FormAction.validateUniqueFields(
					{
						dictionary: dictionaryChannelName,
						form: commonPart,
						collection: "channel"
					},
					res => {
						setErrorUniqueFields(res.nonUnique);
						if (res.allValuesAreUnique) {
							setActiveStep(activeStep + 1);
							setStepValid(false);
						}
					}
				)
			);
			return;
		}

		// Valid config part, do not valid when we're editing a channel
		if (activeStep === 2 && !channelCode) {
			dispatch(
				FormAction.validateUniqueFields(
					{
						dictionary: selectedChannelSubType?.codeDictionary,
						form: configPart,
						collection: "channel"
					},
					res => {
						setErrorUniqueFields(res.nonUnique);
						if (res.allValuesAreUnique) {
							setActiveStep(activeStep + 1);
							setStepValid(false);
						}
					}
				)
			);
			return;
		}

		setActiveStep(activeStep + 1);
		setStepValid(false);
	};
	/**
	 * Go to previous step
	 */
	const handleBack = () => {
		setActiveStep(activeStep - 1);
		topContainer?.current?.scrollIntoView();
	};

	const flatObject = (object, array = [], path = "", type) => {
		for (let key in object) {
			if (typeof object[key] === "object") {
				flatObject(object[key], array, path + key + ".", type);
			} else {
				try {
					object[key] = object[key].trim();
				} catch (e) {
					// Pass
				}
				array.push({
					key: path + key,
					value: object[key],
					valid: true,
					type
				});
			}
		}
		return array;
	};

	const createMappingObject = (mapping = {}) => {
		let mappingKeys = Object.keys(mapping);

		let temporaryMappingObject = {};
		let temporaryMapping = [];

		for (let key of mappingKeys) {
			let logicMapping = mapping[key] ?? "";

			let logicJSON = {};

			let type = "customValue";
			// 1- Try to parse without replacing => customValue
			try {
				logicJSON = JSON.parse(logicMapping);
			} catch {
				// 2- Try to replace the dictionary values => singleValue
				let logic = logicMapping.replaceAll("{{{ json", '"');
				logic = logic.replaceAll(" }}}", '"');

				if (logic) {
					try {
						logicJSON = JSON.parse(logic);
					} catch (e) {
						logicJSON = {};
					}
				}

				type = "singleValue";
			}

			let flatLogic = flatObject(logicJSON, [], `${key}.`, type);

			temporaryMappingObject[key] = logicJSON;
			temporaryMapping = [...temporaryMapping, ...flatLogic];
		}

		setMappingObject(temporaryMappingObject);
		setMapping(temporaryMapping);
	};

	const selectChannel = channel => {
		if (channel) {
			setExtraDatas({});
			// Get channel sub type response handle
			const getChannelSubTypeSuccess = res => {
				handleNext();

				let channelSubType = res?.items[0] ?? {};
				if (channelSubType) {
					setSelectedChannelSubType(channelSubType);
					// Change steps, depending on the channel
					// For somes channels, we need to display only specifics steps
					switch (channelSubType.code) {
						case "GOGR_SCRAPING":
							setDisplayedSteps([...shortSteps, "Fiches Google"]);
							break;
						default:
							setDisplayedSteps(steps);
							break;
					}
				}
			};

			// Configure channel response handle
			const configureChannel = channelCode => {
				setSelectedChannel(channel);

				setCommonPart(prev => {
					return {
						...channel.common,
						code: channelCode
					};
				});

				setConfigPart(channel.config);
				setInputsPart(defaultValueInputs);

				// Define mapping
				createMappingObject(channel.mapping);

				dispatch(
					FormAction.getItemsFromCollection(
						"channel",
						{
							query: {
								documentType: "channelSubType",
								code: channel.subType,
								active: true,
								assistantID: null
							},
							catalog: "CHANNEL"
						},
						getChannelSubTypeSuccess
					)
				);
			};

			// 1- Generate channel code
			dispatch(
				FormAction.generateChannelCode(
					{
						type: channel.type
					},
					res => {
						configureChannel(res.channelCode);
					}
				)
			);
		}
	};

	const onChangeCommonPart = (path, value) => {
		let clone = lod_.cloneDeep(commonPart);
		lod_.set(clone, path, value);
		setCommonPart(clone);
		// When user change a field, we need to remove it from the errorUniqueFields
		if (errorUniqueFields.includes(path)) {
			setErrorUniqueFields(prev => prev.filter(item => item !== path));
		}
	};

	const onChangeInputs = (path, value) => {
		let clone = lod_.cloneDeep(inputsPart);

		lod_.set(clone, path, value);
		setInputsPart(clone);
		// When user change a field, we need to remove it from the errorUniqueFields
		if (errorUniqueFields.includes(path)) {
			setErrorUniqueFields(prev => prev.filter(item => item !== path));
		}
	};

	const onChangeConfigPart = (path, value) => {
		let clone = lod_.cloneDeep(configPart);
		lod_.set(clone, path, value);
		setConfigPart(clone);
	};

	/**
	 * Get actual step content
	 */
	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				return <Step1ChannelChoice validStep={validStep} selectChannel={selectChannel} />;
			case 1:
				return (
					<Step2CommonPart
						validStep={validStep}
						common={commonPart}
						setCommon={setCommonPart}
						channel={selectedChannel}
						onChange={onChangeCommonPart}
						errorUniqueFields={errorUniqueFields}
						dictionaryChannelName={dictionaryChannelName}
					/>
				);
			case 2:
				return (
					<Step3ConfigPart
						validStep={validStep}
						config={configPart}
						commonPart={commonPart}
						setConfigPart={setConfigPart}
						channel={selectedChannel}
						channelSubType={selectedChannelSubType}
						onChange={onChangeConfigPart}
						extraDatas={extraDatas}
						setExtraDatas={setExtraDatas}
					/>
				);
			case 3:
				if (selectedChannelSubType.code === "GOGR_SCRAPING") {
					return <GoogleScrapingConfiguration validStep={validStep} />;
				} else {
					return (
						<Step4Mapping
							validStep={validStep}
							config={configPart}
							channelSubType={selectedChannelSubType}
							mapping={mapping}
							mappingObject={mappingObject}
							setMapping={setMapping}
							setMappingObject={setMappingObject}
						/>
					);
				}
			case 4:
				return (
					<Step5AdvancedSettings
						validStep={validStep}
						// For components
						channel={selectedChannel}
						inputs={inputsPart}
						common={commonPart}
						onChangeCommon={onChangeCommonPart}
						onChangeInputs={onChangeInputs}
					/>
				);
			default:
				return null;
		}
	}

	useEffect(() => {
		setErrorUniqueFields([]);
		setLoading(true);
		setStepValid(false);
		setActiveStep(0);
		setEditingChannel({});
		setSelectedChannel({});
		setExtraDatas({});

		if (open) {
			if (channelCode) {
				dispatch(
					FormAction.getItemsFromCollection(
						"channel",
						{
							query: {
								code: channelCode,
								active: { $in: [true, false, null] }
							}
						},
						res => {
							let items = res?.items ?? [];

							if (items.length > 0) {
								let channel = items[0];
								setEditingChannel(channel);

								let clonedChannel = lod_.cloneDeep(channel);

								createMappingObject({
									contact: clonedChannel.contact,
									context: clonedChannel.context
								});

								setConfigPart(clonedChannel.config);
								if (
									lod_.isNil(clonedChannel.answerGenerateConfig) ||
									lod_.isEmpty(clonedChannel.answerGenerateConfig)
								) {
									setInputsPart(defaultValueInputs);
								} else {
									let merged = lod_.merge(defaultValueInputs, clonedChannel.answerGenerateConfig);
									setInputsPart(merged);
								}

								delete clonedChannel.contact;
								delete clonedChannel.context;
								delete clonedChannel.config;
								delete clonedChannel._id;

								setCommonPart(clonedChannel);

								const getChannelSubTypeSuccess = ({ items }) => {
									let subType = items[0];

									switch (subType.code) {
										case "GOGR_SCRAPING":
											setDisplayedSteps([...shortSteps, "Fiches Google"]);
											break;
										default:
											setDisplayedSteps(steps);
											break;
									}

									setSelectedChannelSubType(subType);
									setActiveStep(1);
									setLoading(false);
								};

								const getChannelSuccess = ({ items }) => {
									setSelectedChannel(items[0]);

									// Get channel sub type
									dispatch(
										FormAction.getItemsFromCollection(
											"channel",
											{
												query: {
													documentType: "channelSubType",
													code: channel.subType,
													active: true,
													assistantID: null
												},
												catalog: "CHANNEL"
											},
											getChannelSubTypeSuccess
										)
									);
								};

								dispatch(
									FormAction.getItemsFromCollection(
										"channel",
										{
											query: {
												documentType: "channel",
												subType: channel.subType,
												type: channel.type,
												active: true,
												assistantID: null
											},
											catalog: "CHANNEL"
										},
										getChannelSuccess
									)
								);
							} else {
								dispatch(
									display({
										type: "error",
										message: i18n.t("CHANNEL.ERRORS.cannotFindCanal")
									})
								);
								handleClose();
							}
						}
					)
				);
			} else {
				setCommonPart({});
				setConfigPart({});
				setInputsPart({});
				setSelectedChannel({});
				setMapping([]);
				setMappingObject({});
				setLoading(false);
			}
		}
	}, [open]);

	/**
	 * If channel has advanced settings, add a step
	 */
	useEffect(() => {
		if (selectedChannel) {
			let advancedSettings = selectedChannel?.advancedSettings ?? {};

			if (!lod_.isEmpty(advancedSettings)) {
				setExtraSteps([t("CHANNEL.STEPS.CHOICE.advancedSettings")]);
			} else {
				setExtraSteps([]);
			}
		} else {
			setExtraSteps([]);
		}
	}, [selectedChannel]);

	/**
	 * Main component
	 */
	return (
		<Dialog
			fullWidth
			maxWidth="xxl"
			open={open}
			onClose={close}
			PaperProps={{
				style: {
					height: "100%",
					width: "100%",
					maxWidth: "100%"
				}
			}}
		>
			{loading ? (
				<MDBox
					sx={{
						height: "100%",
						width: "100%"
					}}
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<CircularProgress color="info" size="40" />
				</MDBox>
			) : (
				<>
					{/* {activeStep > 0 && ( */}
					<DialogTitle>
						<MDBox>
							<Stepper activeStep={activeStep} alternativeLabel>
								{displayedSteps.map(label => (
									<Step key={label}>
										<StepLabel>{label}</StepLabel>
									</Step>
								))}
							</Stepper>
						</MDBox>
					</DialogTitle>
					{/* )} */}

					<DialogContent>
						<div data-id="top-container" ref={topContainer}></div>
						{getStepContent(activeStep)}
					</DialogContent>
					<DialogActions>
						<MDButton variant="outlined" color="info" onClick={close}>
							{i18n.t("SETTINGS.cancel")}
						</MDButton>
						<MDButton
							disabled={activeStep === 0 || (channelCode && activeStep <= 1)}
							variant="contained"
							color="light"
							onClick={handleBack}
						>
							{i18n.t("SETTINGS.CHARTS.NEW.STEPS.back")}
						</MDButton>
						<MDButton
							disabled={!stepValid}
							variant="contained"
							color="info"
							onClick={!isLastStep ? handleNext : submit}
						>
							{isLastStep
								? channelCode
									? i18n.t("SETTINGS.edit")
									: i18n.t("SETTINGS.save")
								: i18n.t("SETTINGS.CHARTS.NEW.STEPS.next")}
						</MDButton>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
}
