/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-cond-assign */
/* eslint-disable react/no-unknown-property */
import MDBox from "components/Basics/MDBox"; // Importing a custom MDBox component
import "./style.css"; // Importing styles
import React, { useState, useRef, useEffect } from "react"; // Importing React hooks
import ReactDOMServer from "react-dom/server"; // Importing ReactDOMServer for rendering static markup
import lod_ from "lodash"; // Importing lodash for its debounce utility
import DictionaryMenu from "pages/settings/filters/DictionaryMenu";
import { Icon, Tooltip } from "@mui/material";
import FaibrikVariable from "./FaibrikVariable";

// Main component that handles the advanced input functionality
const AdvancedInput = ({
	valueCallback = () => {}, // Callback to handle value changes
	value: defaultValue = "", // Default value of the input
	dictionary, // Dictionary to use for the input for variable paths
	onChange, // Callback to handle input changes
	label = null, // Label to display when the input is empty
	rounded = false, // Whether to round the input or not
	trigger = null, // Trigger to update the input value from outside, must be unique like a key
	...rest
}) => {
	const editorRef = useRef(null);

	/**
	 * Function to decode a string containing handlebar-like expressions to JSX
	 * @param {string} content - The string content to decode
	 * @returns {string} - Decoded content as a static HTML string
	 */
	const decode = content => {
		// Check if the content is not a key to a dictionary
		let path = content.replaceAll(".", ".items.");
		let item = lod_.get(dictionary, path);
		if (item) {
			// If item is found, convert it to a handlebar-like expression
			content = `{{{ ${content} }}}`;
		}

		// Regular expression to find handlebar-like expressions
		const regex = /\{\{\{\s*(.+?)\s*\}\}\}/g;
		const parts = []; // Array to hold parts of the content
		let lastIndex = 0; // To keep track of the last matched index

		let match;
		// Loop through all matches in the content
		while ((match = regex.exec(content)) !== null) {
			// Push the text before the match
			if (match.index > lastIndex) {
				parts.push(content.substring(lastIndex, match.index));
			}

			// Extract and trim the variable inside the handlebar expression
			const variable = match[1].trim();

			// Push the custom variable component
			parts.push(<FaibrikVariable key={match.index} path={variable} dictionary={dictionary} />);

			lastIndex = regex.lastIndex; // Update last index
		}

		// Push the remaining text after the last match
		if (lastIndex < content.length) {
			parts.push(content.substring(lastIndex));
		}

		let html = ReactDOMServer.renderToStaticMarkup(parts);

		html = html.replaceAll("</span> ", "</span>&nbsp;");
		// Return the combined parts as a static HTML string
		return html;
	};

	/**
	 * Function to encode a JSX content to a string containing handlebar-like expressions
	 * @param {string} content - The JSX content to encode
	 * @returns {string} - Encoded content as a string
	 */
	const encode = html => {
		// 2- Parse the string to HTML
		let parser = new DOMParser();
		let doc = parser.parseFromString(html, "text/html");

		// 3- Get all span that are class="variable-component" from html
		let variableComponents = doc.querySelectorAll(
			"span[data-attribute='advanced-input-component-variable']"
		);

		// 4- Replace the span with the value of the data-value attribute in {{{ value }}}
		variableComponents.forEach(variableComponent => {
			let path = variableComponent.getAttribute("data-path");
			let textNode = doc.createTextNode(`{{{ ${path} }}}`);
			variableComponent.replaceWith(textNode);
		});

		// Get the full html content
		html = doc.documentElement.outerHTML;
		// Get only the content in the <body></body> tags
		html = html.substring(html.indexOf("<body>") + 6, html.indexOf("</body>"));
		html = html.replace(/&nbsp;/g, " ");

		return html;
	};

	// State to hold the current value of the input, initialized with decoded content
	const [value, setValue] = useState(decode(defaultValue ?? ""));
	const [anchorElInput, setAnchorElInput] = useState(null);
	const [cursorSavedPosition, setCursorSavedPosition] = useState(0);

	// When value change, send it to callback to see if it needs to be updated here
	// because updating the value here will cause the cursor to move to the start of the input
	// so we need to update value only when we change all the input's value
	useEffect(() => {
		valueCallback(defaultValue, setValue, decode);
	}, [defaultValue]);

	// Trigger allow to update the input value from outside
	// because else state will be the same across all instances
	// of this component
	// ~ same functionnement as a key
	useEffect(() => {
		// TO update the value when trigger we need to
		// reset the value to null and set it after a timeout of 0
		// It can cause a flicker but it's the only way to update the value
		setValue(null);
		setTimeout(() => {
			setValue(decode(defaultValue ?? ""));
		}, 0);
	}, [trigger]);

	// Recursive function
	const getNodePreviousTextLength = (node, length = 0) => {
		if (node.outerHTML) {
			length += node.outerHTML.length;
		} else if (node.textContent) {
			length += node.textContent.length;
		}

		if (node.previousSibling) {
			return getNodePreviousTextLength(node.previousSibling, length);
		}

		return length;
	};

	/**
	 * Overriding the default paste behavior to insert the pasted text at the cursor position
	 * @param {*} html
	 * @returns
	 */
	function insertTextAtCursor(text) {
		let selection = window.getSelection();

		if (!selection.rangeCount) {
			return;
		}

		// Get the current range (i.e., the caret position)
		let range = selection.getRangeAt(0);

		// Delete any selected content
		range.deleteContents();

		// Insert the fragment at the current range
		range.insertNode(document.createRange().createContextualFragment(text));

		// Move the selection cursor to after the inserted content
		range.collapse(false);
		selection.removeAllRanges();
		selection.addRange(range);

		// Update the content of the editor
		onChange(encode(editorRef.current.innerHTML));
	}

	/**
	 * Save the cursor position when right clicking on the editor or when losing focus
	 * Used to insert the variable at the cursor position
	 * @param {object} e - The event object
	 */
	function saveCursorPosition(e) {
		e.preventDefault();

		// Get the current selection range
		const selection = window.getSelection();
		const range = selection.getRangeAt(0);
		// Get the start offset of the selection
		const startOffset = range.startOffset;
		// Get the total length of the text before the cursor
		let totalBeforeLength = getNodePreviousTextLength(range.startContainer?.previousSibling ?? {});
		totalBeforeLength += startOffset;

		let target = e.target;
		let parent = target.parentElement;

		let container = range.commonAncestorContainer;

		if (target === container || parent === container) {
			if (startOffset > 0) {
				// Add a the end
				totalBeforeLength = editorRef.current.innerHTML.length;
			} else {
				// Add at the beginning
				totalBeforeLength = 0;
			}
		}

		// Save the cursor position
		setCursorSavedPosition(totalBeforeLength);
	}

	return (
		<>
			<MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="stretch">
				<MDBox
					flex="1"
					ref={editorRef}
					contentEditable="true" // Make the div contenteditable
					className="advancedInputStyle"
					borderRadius="md"
					onInput={e => {
						const el = e.currentTarget; // Current target element
						onChange(encode(el.innerHTML));
					}}
					onPaste={e => {
						const plainText = e.clipboardData.getData("text/plain");
						insertTextAtCursor(plainText);
						e.preventDefault();
					}}
					dangerouslySetInnerHTML={{ __html: value }} // Set initial content
					// right click event to open the dictionary menu
					sx={{
						"&:empty:before": {
							content: label ? `'${label}'` : '""',
							color: "#ccc"
						},

						"&:empty:focus:before": {
							content: '""'
						}
					}}
					onContextMenu={e => {
						// Save the cursor position
						saveCursorPosition(e);

						// Create a dummy element at the cursor position
						const dummyAnchor = document.createElement("div");
						dummyAnchor.style.position = "absolute";
						dummyAnchor.style.top = `${e.clientY}px`;
						dummyAnchor.style.left = `${e.clientX}px`;
						dummyAnchor.attributes["data-attribute"] = "advanced-input-component-dummy";
						document.body.appendChild(dummyAnchor);
						// Set the anchor element to the dummy element
						setAnchorElInput(dummyAnchor);
					}}
					// when loose focus
					onBlur={e => {
						// Save the cursor position
						saveCursorPosition(e);
					}}
					onCopy={e => {
						// When copying text, we want to copy the HTML
						// content to copy variables as well
						const selection = window.getSelection();
						const range = selection.getRangeAt(0);
						const clonedRange = range.cloneContents();
						const div = document.createElement("div");
						div.appendChild(clonedRange);
						navigator.clipboard.writeText(div.innerHTML);
						e.preventDefault();
					}}
					{...rest}
				></MDBox>
				<Tooltip title="Ajouter une variable" placement="top">
					<MDBox
						className="endButtonboxInputStyle"
						display="flex"
						justifyContent="center"
						alignItems="center"
						bgColor="light"
						style={{
							borderRadius: rounded ? "0 0.375rem 0.375rem 0" : "0"
						}}
						onClick={e => {
							// Open the dictionary menu
							setAnchorElInput(e.currentTarget);
						}}
					>
						<Icon fontSize="small">superscript</Icon>
					</MDBox>
				</Tooltip>
			</MDBox>

			<DictionaryMenu
				placement="right"
				dictionary={dictionary}
				anchorEl={anchorElInput}
				clickableLevels={false}
				handleInsertText={variable => {
					setAnchorElInput(null);
					// Create a variable element
					const variableElement = ReactDOMServer.renderToStaticMarkup(
						<FaibrikVariable path={variable} dictionary={dictionary} />
					);

					// Get the current content of the editor
					let content = editorRef.current.innerHTML;
					// Remove &nbsp; from the content and replace by spaces
					content = content.replace(/&nbsp;/g, " ");
					// Insert the variable at the cursor position

					let afterContent = content.substring(cursorSavedPosition);

					content = content.substring(0, cursorSavedPosition) + variableElement + afterContent;

					// Set new cursor position after the inserted variable
					let newCursorPosition = cursorSavedPosition + variableElement.length;

					// Replace spaces by &nbsp; otherwise they will be removed
					content = content.replaceAll("</span> ", "</span>&nbsp;");

					setCursorSavedPosition(newCursorPosition);

					editorRef.current.innerHTML = content;
					onChange(encode(content));
				}}
				handleClose={() => {
					// Remove the dummy element when closing the menu
					if (
						anchorElInput &&
						anchorElInput.attributes["data-attribute"] === "advanced-input-component-dummy"
					) {
						document?.body?.removeChild(anchorElInput);
					}
					setAnchorElInput(null);
				}}
			/>
		</>
	);
};

export default AdvancedInput;
