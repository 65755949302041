/* eslint-disable react/jsx-no-duplicate-props */

import lod_ from "lodash";
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Icon,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	Tooltip,
	Typography
} from "@mui/material";

import MDBox from "components/Basics/MDBox";
import MDButton from "components/Basics/MDButton";
import MDTypography from "components/Basics/MDTypography";
import MDInput from "components/Basics/MDInput";
import { t } from "i18next";
import { useEffect, useState } from "react";
import SettingsActions from "redux-react/actions/settingsActions";
import { useDispatch } from "react-redux";
import { display } from "redux-react/reducers/snackBarReducer";
import useCopyToClipboard from "helpers/useCopyToClipboard";
import { v4 as uuidv4 } from "uuid";

/**
 * Pannel to add / edit access from faibrikAccess for resource
 * @param {*} param0
 * @returns
 */
const AccessResource = ({ assistantID, isEmpty, setValues, values }) => {
	const [listAccess, setListAccess] = useState([]);
	const [valueAccess, setValueAccess] = useState({});
	const [isSecretID, setIsSecretID] = useState(true);
	const [isSecretClient, setIsSecretClient] = useState(true);
	const [isSelect, setIsSelect] = useState(true);

	const [isActive, setIsActive] = useState(false);

	const [nameApi, setNameApi] = useState("");
	const [oldNameApi, setOldNameApi] = useState(null);

	const [, handleCopy] = useCopyToClipboard(2000);

	const dispatch = useDispatch();

	const getApiAccess = () => {
		const onSuccess = res => {
			if (!lod_.isEmpty(res.access) && !isEmpty) {
				setListAccess(res.access);

				let APIFound = res.access.find(item =>
					item.scope?.some(str => str.includes(`:${values?.code}`))
				);

				if (!lod_.isNil(APIFound)) {
					setNameApi(APIFound.name);
					setOldNameApi(APIFound.name);
					setValueAccess(APIFound);
					setIsActive(true);
				} else {
					setNameApi("");
					setIsActive(false);
					setValueAccess({});
				}
			} else {
				setListAccess(!lod_.isEmpty(res.access) ? res.access : []);
				setValueAccess({});
				setIsActive(false);
			}
		};
		const onFailure = res => {
			setListAccess([]);
			setValueAccess({});
			setIsActive(false);
		};
		dispatch(SettingsActions.getApiAccess(assistantID, onSuccess, onFailure));
	};

	useEffect(() => {
		getApiAccess();
	}, []);

	useEffect(() => {
		setValues(prev => {
			let cloneValues = lod_.cloneDeep(prev);
			cloneValues.valueAccess = valueAccess;
			if (!lod_.isNil(oldNameApi)) cloneValues.valueAccess.oldNameApi = oldNameApi;
			if (isSelect === false) {
				cloneValues.valueAccess.isSelect = false;
			} else {
				delete cloneValues.valueAccess.isSelect;
			}
			if (isActive === false) {
				cloneValues.valueAccess = {};
				cloneValues.valueAccess.isActive = false;
			} else {
				delete cloneValues.valueAccess.isActive;
			}
			return cloneValues;
		});
	}, [valueAccess]);

	useEffect(() => {
		setValues(prev => {
			let cloneValues = lod_.cloneDeep(prev);
			cloneValues.valueAccess = valueAccess;
			if (isActive === false) {
				cloneValues.valueAccess = {};
				cloneValues.valueAccess.isActive = false;
			} else {
				delete cloneValues.valueAccess.isActive;
			}
			return cloneValues;
		});
	}, [isActive]);

	const copyValue = input => {
		let valueCopy;
		switch (input) {
			case "id": {
				valueCopy = valueAccess.clientId;
				break;
			}
			case "secret": {
				valueCopy = valueAccess.clientSecret;
				break;
			}
			default:
				break;
		}

		handleCopy(valueCopy);
		dispatch(
			display({
				message: t("FORMS.copyToClipboard"),
				type: "success"
			})
		);
	};

	const getInputStyle = input => {
		switch (input) {
			case "id": {
				if (isSecretID) {
					return {
						color: "transparent",
						textShadow: "0 0 5px rgba(0,0,0,0.5)"
					};
				}
				break;
			}
			case "secret": {
				if (isSecretClient) {
					return {
						color: "transparent",
						textShadow: "0 0 5px rgba(0,0,0,0.5)"
					};
				}
				break;
			}
			default:
				return {};
		}
		return {};
	};

	const resetSecretInput = input => {
		let newUUID = uuidv4();

		switch (input) {
			case "id": {
				setValueAccess(prev => {
					let copyValue = lod_.cloneDeep(prev);
					copyValue.clientId = newUUID;
					return copyValue;
				});
				break;
			}
			case "secret": {
				setValueAccess(prev => {
					let copyValue = lod_.cloneDeep(prev);
					copyValue.clientSecret = newUUID;
					return copyValue;
				});
				break;
			}
			default:
				break;
		}
	};

	const showClientInput = () => {
		setIsSecretClient(false);
	};

	const showIDInput = () => {
		setIsSecretID(false);
	};

	const addNewAccess = () => {
		setIsSecretID(true);
		setIsSecretClient(true);

		setIsSelect(false);

		setNameApi("");
		setValueAccess(prev => {
			let copyValue = lod_.cloneDeep(prev);
			let newUUIDclientId = uuidv4();
			let newUUIDclientSecret = uuidv4();

			copyValue.clientId = newUUIDclientId;
			copyValue.name = "";
			copyValue.clientSecret = newUUIDclientSecret;
			copyValue.scope = [];
			return copyValue;
		});
	};

	const selectNewAccess = () => {
		setIsSecretID(true);
		setIsSecretClient(true);

		setIsSelect(true);
		setNameApi("");
		setValueAccess({});
	};

	const changeApi = name => {
		let APIFound = listAccess.find(item => item.name === name);
		setIsSecretClient(true);
		setIsSecretID(true);
		if (!lod_.isNil(APIFound)) {
			setNameApi(APIFound.name);
			setValueAccess(APIFound);
		} else {
			setNameApi("");
			setValueAccess({});
		}
	};

	const checkCheckboxValue = input => {
		if (lod_.isNil(valueAccess.scope)) {
			setValueAccess(prev => {
				let copyValue = lod_.cloneDeep(prev);
				copyValue.scope = [];
				return copyValue;
			});
			return false;
		}

		let scopeFound = valueAccess.scope.find(str => str === `${input}:resource:${values?.code}`);
		if (!lod_.isNil(scopeFound)) return true;

		return false;
	};

	const handleSelectScope = (e, input) => {
		setValueAccess(prev => {
			let copyValue = lod_.cloneDeep(prev);
			if (e.target.checked === false) {
				copyValue.scope = copyValue.scope.filter(
					item => item !== `${input}:resource:${values?.code}`
				);
			} else if (!copyValue.scope.includes(`${input}:resource:${values?.code}`)) {
				copyValue.scope.push(`${input}:resource:${values?.code}`);
			}
			return copyValue;
		});
	};

	const handleChangeName = value => {
		setValueAccess(prev => {
			let copyValue = lod_.cloneDeep(prev);
			copyValue.name = value;
			return copyValue;
		});
	};

	const handleChangeActive = value => {
		setIsActive(value);
	};

	return (
		<MDBox display="flex" flexDirection="column" pt={2}>
			<MDBox display="flex" alignItems="center" pb={3}>
				<Typography variant="h4">Configuration de la ressource</Typography>
				<Switch
					color="info"
					checked={isActive}
					onChange={(e, value) => {
						handleChangeActive(e.target.checked);
					}}
				/>
				<span>Activé</span>
			</MDBox>
			{isActive && (
				<>
					<Typography variant="h4" pb={2}>
						Liste API
					</Typography>

					<MDBox display="flex" alignItems="center">
						{isSelect ? (
							<FormControl style={{ width: "30%" }}>
								<InputLabel id="select-label">Liste des accès API</InputLabel>
								<Select
									labelId="select-label"
									id="select"
									label="Liste des accès API"
									value={lod_.isEmpty(nameApi) ? "" : nameApi}
									onChange={e => changeApi(e.target.value)}
								>
									{listAccess.map((option, index) => (
										<MenuItem key={index} value={option.name}>
											{option.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						) : (
							<MDInput
								style={{ width: "30%" }}
								className="Nom de l'accès API"
								value={valueAccess?.name}
								label="Nom de l'accès API"
								onChange={e => handleChangeName(e.target.value)}
							/>
						)}

						<Typography pr={1} pl={1}>
							ou
						</Typography>

						{isSelect ? (
							<MDButton
								style={{ pointerEvents: "visible" }}
								onClick={() => {
									addNewAccess();
								}}
								color="info"
								label=""
							>
								Ajouter un accès API
							</MDButton>
						) : (
							<MDButton
								style={{ pointerEvents: "visible" }}
								onClick={() => {
									selectNewAccess();
								}}
								label=""
								color="info"
							>
								Selectionner un accès API
							</MDButton>
						)}
					</MDBox>
					{!lod_.isEmpty(valueAccess) && (
						<MDBox style={{ display: "flex", flexDirection: "column" }}>
							<MDBox style={{ display: "flex", alignItems: "flex-end" }}>
								<Typography variant="h4" pt={2} pr={1}>
									Clées API
								</Typography>
							</MDBox>

							<MDBox pt={2} style={{ display: "flex" }}>
								<MDBox style={{ width: "100%" }}>
									<MDInput
										className="dialogInput"
										label="CLIENT ID"
										value={valueAccess?.clientId || null}
										disabled
										inputProps={{
											style: getInputStyle("id")
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													{isSecretID ? (
														<Tooltip title="Visualiser les clées API">
															<IconButton
																color="info"
																style={{ pointerEvents: "visible" }}
																onClick={() => {
																	showIDInput();
																}}
																variant="contained"
															>
																<Icon>visibility</Icon>
															</IconButton>
														</Tooltip>
													) : (
														<MDBox display="flex">
															<MDBox pr={1}>
																<Tooltip title="Regénérer la clée API">
																	<IconButton
																		color="info"
																		style={{ pointerEvents: "visible" }}
																		onClick={() => {
																			resetSecretInput("id");
																		}}
																	>
																		<Icon>autorenew</Icon>
																	</IconButton>
																</Tooltip>
															</MDBox>
															<MDBox>
																<Tooltip title="Copier la clée">
																	<IconButton
																		color="info"
																		style={{ pointerEvents: "visible" }}
																		onClick={() => {
																			copyValue("id");
																		}}
																	>
																		<Icon>copy</Icon>
																	</IconButton>
																</Tooltip>
															</MDBox>
														</MDBox>
													)}
												</InputAdornment>
											)
										}}
										style={{ width: "50%" }}
									/>
								</MDBox>
							</MDBox>

							<MDBox pt={2} style={{ display: "flex" }}>
								<MDBox style={{ width: "100%" }}>
									<MDInput
										className="dialogInput"
										label="CLIENT SECRET"
										value={valueAccess?.clientSecret || null}
										disabled
										inputProps={{
											style: getInputStyle("secret")
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													{isSecretClient ? (
														<Tooltip title="Visualiser les clées API">
															<IconButton
																color="info"
																style={{ pointerEvents: "visible" }}
																onClick={() => {
																	showClientInput();
																}}
																variant="contained"
															>
																<Icon>visibility</Icon>
															</IconButton>
														</Tooltip>
													) : (
														<MDBox display="flex">
															<MDBox pr={1}>
																<Tooltip title="Regénérer la clée API">
																	<IconButton
																		color="info"
																		style={{ pointerEvents: "visible" }}
																		onClick={() => {
																			resetSecretInput("secret");
																		}}
																	>
																		<Icon>autorenew</Icon>
																	</IconButton>
																</Tooltip>
															</MDBox>
															<MDBox>
																<Tooltip title="Copier la clée">
																	<IconButton
																		color="info"
																		style={{ pointerEvents: "visible" }}
																		onClick={() => {
																			copyValue("secret");
																		}}
																	>
																		<Icon>copy</Icon>
																	</IconButton>
																</Tooltip>
															</MDBox>
														</MDBox>
													)}
												</InputAdornment>
											)
										}}
										style={{ width: "50%" }}
									/>
								</MDBox>
							</MDBox>

							<MDBox style={{ display: "flex", flexDirection: "column" }}>
								<MDBox>
									<Typography variant="h4" pt={2} pr={1}>
										Autorisation(s)
									</Typography>
								</MDBox>
								<FormGroup style={{ display: "flex", flexDirection: "row" }}>
									<FormControlLabel
										control={
											<Checkbox
												checked={checkCheckboxValue("read")}
												onClick={e => {
													handleSelectScope(e, "read");
												}}
											/>
										}
										label="Lecture"
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={checkCheckboxValue("write")}
												onClick={e => {
													handleSelectScope(e, "write");
												}}
											/>
										}
										label="Ecriture"
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={checkCheckboxValue("delete")}
												onClick={e => {
													handleSelectScope(e, "delete");
												}}
											/>
										}
										label="Suppression"
									/>
								</FormGroup>
							</MDBox>
						</MDBox>
					)}
				</>
			)}
		</MDBox>
	);
};

export default AccessResource;
